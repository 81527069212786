export const techs = [
  {
    title: "CSS3",
    logo: "css3",
    level: "2",
    description: "Mais de 3 anos de experiência na estilização de aplicações web e mobile.",
  },
  {
    title: "SASS",
    logo: "sass",
    level: "1",
    description: "Mais de 1 ano de experiência na estilização de aplicações web e mobile.",
  },
  {
    title: "VueJS",
    logo: "vuejs",
    level: "2",
    description: "Mais de 1 ano de experiência na construção e manutenção de aplicações web e mobile.",
  },
  {
    title: "ReactJS",
    logo: "react",
    level: "2",
    description: "Mais de 1 ano de experiência na construção e manutenção de aplicações web e mobile.",
  },
  {
    title: "TypeScript",
    logo: "typescript",
    level: "1",
    description: "Cerca de 1 ano de experiência na construção e manutenção de aplicações web.",
  },
  {
    title: "NodeJS",
    logo: "nodejs",
    level: "2",
    description: "Mais de 1 ano de experiência na construção e manutenção de API's.",
  },
  {
    title: "Java",
    logo: "java",
    level: "2",
    description: "Mais de 2 anos de experiência na construção e manutenção de aplicações desktop.",
  },
  {
    title: "Spring Boot",
    logo: "spring",
    level: "1",
    description: "Mais de 1 ano de experiência na construção e manutenção de API's.",
  },
  {
    title: "Python",
    logo: "python",
    level: "1",
    description: "Mais de 1 ano de experiência acadêmica.",
  },
  {
    title: "PHP",
    logo: "php",
    level: "2",
    description: "Mais de 2 anos de experiência na construção e manutenção de aplicações web.",
  },
  {
    title: "MySQL",
    logo: "mysql",
    level: "2",
    description: "Mais de 3 anos de experiência na construção e manutenção de banco de dados para aplicações.",
  },
  {
    title: "Git",
    logo: "git",
    level: "2",
    description: "Mais de 4 anos de experiência no versionamento de códigos.",
  },
  {
    title: "Docker",
    logo: "docker",
    level: "1",
    description: "Mais de 1 ano de experiência na criação e utilização de containers.",
  },
  {
    title: "Figma",
    logo: "figma",
    level: "2",
    description: "Mais de 1 ano de experiência na construção de protótipos web e mobile, aplicando conceitos de UX/UI.",
  },
];
